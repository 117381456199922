import { createTransform } from 'redux-persist';

import { IAuthState } from '@/store/slices/auth-slice';
import { IChatHistoryState } from '@/store/slices/chat-history-slice';

export const clearLoading = createTransform<IChatHistoryState, IChatHistoryState>(
    undefined,
    (outboundState, key) => {
        if (key === 'chatHistory') {
            outboundState.chatHistory.forEach((item) => delete item.loading);
        }
        return outboundState;
    },
    { whitelist: ['chatHistory'] },
);
