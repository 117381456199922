import { User } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';

import { IAuthState, updateAuth } from '../slices/auth-slice';
import { RootState } from '../store';

export const useAuthStore = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.auth.isLoading);
    const apiKey = useSelector((state: RootState) => state.auth.apiKey);
    const appUser = useSelector((state: RootState) => state.auth.appUser);
    const showTerms = useSelector((state: RootState) => state.auth.showTerms);
    const noFirebase = useSelector((state: RootState) => state.auth.noFirebase);

    const updateAuthAction = (patch: Partial<IAuthState>) => {
        dispatch(updateAuth(patch));
    };

    const setApiKeyAction = (apiKey: string) => {
        dispatch(updateAuth({ apiKey }));
    };

    const setIsLoadingAction = (isLoading: boolean) => {
        dispatch(updateAuth({ isLoading }));
    };

    const setAppUserAction = (appUser: User | null) => {
        dispatch(updateAuth({ appUser }));
    };

    return {
        showTerms,
        isLoading,
        apiKey,
        appUser,
        noFirebase,
        updateAuthAction,
        setApiKeyAction,
        setIsLoadingAction,
        setAppUserAction,
    };
};
