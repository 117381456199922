import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore';
import { md5 } from 'js-md5';

import { db } from '@/config/firebase';
import { UploadedFile } from '@/store/slices/uploaded-files-slice';
import { compact } from '@/utils/helper';
import { Chat, Message, SharedChat } from '@/utils/types';
import { captureException } from '@sentry/nextjs';

function formatChat(data: any = {}): Chat {
    return compact({
        ...data,
        id: data.id,
        title: data.title || 'Untitled chat',
        history: data.history || [],
        retrievalDataset: data.retrievalDataset,
        persona: data.persona,
        modelName: data.modelName,
        createdAt: data.createdAt?.toDate(),
        updatedAt: data.updatedAt?.toDate(),
        sharedAt: data.sharedAt?.toDate(),
    });
}

export const ChatResource = {
    async fetchAll(uid: string) {
        try {
            const response = await getDocs(
                query(collection(db, 'chats'), where('uid', '==', uid), where('isDeleted', '==', false)),
            );
            if (response.empty) {
                return { data: [], err: null };
            }
            let chats: Chat[] = [];
            response.forEach((doc) => {
                chats.push(formatChat({ ...doc.data(), id: doc.id }));
            });
            return { data: chats, err: null };
        } catch (e) {
            captureException(e);
            return { data: [], err: 'missing' };
        }
    },

    async fetch(uid: string, id: string) {
        try {
            const response = await getDoc(doc(db, 'chats', id));
            if (!response.exists()) {
                return { data: null, err: 'missing' };
            }
            return { data: formatChat({ ...response.data(), id: response.id }), err: null };
        } catch (e) {
            captureException(e);
            return { data: null, err: 'missing' };
        }
    },

    create(uid: string, chat: Partial<Chat>): Chat {
        const chatRef = doc(collection(db, 'chats'));

        const newChat = compact({
            ...formatChat(chat),
            createdAt: new Date(),
            updatedAt: new Date(),
            uid,
            isDeleted: false,
            isLoading: null,
        });
        setDoc(chatRef, newChat);
        newChat.id = chatRef.id;
        return newChat;
    },

    deleteAll(uid: string, ids: string[]) {
        const batch = writeBatch(db);
        for (let id of ids) {
            const chatRef = doc(collection(db, 'chats'), id);
            batch.update(chatRef, { uid, deletedAt: new Date(), isDeleted: true });
        }
        batch.commit();
    },

    delete(uid: string, id: string) {
        const chatRef = doc(collection(db, 'chats'), id);
        return updateDoc(chatRef, { uid, deletedAt: new Date(), isDeleted: true });
    },

    update(uid: string, id: string, chat: Partial<Chat>) {
        const chatRef = doc(collection(db, 'chats'), id);
        const nextChat = compact({ ...chat, uid, updatedAt: new Date(), isLoading: null, id: null });
        return updateDoc(chatRef, nextChat);
    },
};

type UserData = {
    apiKey: string;
    userAcceptedTerms?: boolean;
    featureSet?: Record<string, boolean>;
};

export const UserResource = {
    updateUserData(uid: string, data: Partial<UserData>) {
        const userRef = doc(db, 'users', uid);
        return setDoc(userRef, data, { merge: true });
    },
    async fetch(uid: string) {
        try {
            const response = await getDoc(doc(db, 'users', uid));
            if (!response.exists()) {
                return { data: { apiKey: '', userAcceptedTerms: false }, err: null };
            }
            return { data: response.data() as UserData, err: null };
        } catch (e) {
            captureException(e);
            return { data: null, err: 'missing' };
        }
    },
};

export const FileResource = {
    async fetchAll(uid: string) {
        try {
            const response = await getDocs(collection(db, 'users', uid, 'uploadedFiles'));
            if (response.empty) {
                return { data: [], err: null };
            }
            const files: any[] = [];
            response.forEach((doc) => {
                files.push({ ...doc.data(), id: doc.id });
            });
            return { data: files, err: null };
        } catch (e) {
            captureException(e);
            return { data: null, err: 'missing' };
        }
    },
    addFile(uid: string, file: Omit<UploadedFile, 'id'>) {
        const fileRef = collection(db, 'users', uid, 'uploadedFiles');
        addDoc(fileRef, file);
        return fileRef.id;
    },
    removeFile(uid: string, fileId: string) {
        const fileRef = doc(db, 'users', uid, 'uploadedFiles', fileId);
        return deleteDoc(fileRef);
    },
    updateFile(uid: string, fileId: string, file: Partial<UploadedFile>) {
        const fileRef = doc(db, 'users', uid, 'uploadedFiles', fileId);
        return updateDoc(fileRef, { ...file, lastUsed: new Date().toISOString() });
    },
};

export const SharedChatResource = {
    async fetch(id: string) {
        try {
            const response = await getDoc(doc(db, 'sharedChats', id));
            if (!response.exists()) {
                return { data: null, err: 'missing' };
            }
            return { data: formatChat({ ...response.data(), id: response.id }), err: null };
        } catch (e) {
            captureException(e);
            return { data: null, err: 'missing' };
        }
    },

    create(uid: string, chat: Partial<SharedChat>): SharedChat {
        const chatRef = doc(db, 'sharedChats', chat.id!);

        const newChat = compact({
            ...formatChat(chat),
            createdAt: new Date(),
            updatedAt: new Date(),
            uid,
            isDeleted: false,
            isLoading: null,
            chatId: chat.chatId,
        });
        setDoc(chatRef, newChat);
        newChat.id = chatRef.id;
        return newChat;
    },
};

interface ChatMetadata extends Partial<Chat> {
    createAt?: Date;
    uid: string;
}

const snapshotIds = new Set<string>();

function createSnapshot(uid: string, chatId: string, history: Message[]) {
    const strForHash = history
        .map((msg) => `${msg.type}|${msg.text}|${msg.image_url ?? ''}|${msg.media_type ?? ''}|${msg.thumbs_up ?? ''}`)
        .join('|');
    const hash = md5.create().update(strForHash);
    const md5Hash = hash.hex();
    if (snapshotIds.has(`${chatId}|${md5Hash}`)) return md5Hash;
    snapshotIds.add(`${chatId}|${md5Hash}`);
    const snapshotRef = doc(db, 'chatMetadata', chatId, 'snapshots', md5Hash);
    setDoc(snapshotRef, { uid, history, createdAt: new Date() }).catch(() => {});
    return md5Hash;
}

export const ChatMetadata = {
    addMetadata(uid: string, chatId: string, extras: any) {
        const eventRef = doc(db, 'chatMetadata', chatId);
        return setDoc(eventRef, { ...extras, uid }, { merge: true });
    },
    async registerEvent(uid: string, chatId: string, event: MetadataEvent, history: Message[]) {
        let id;
        try {
            const eventRef = collection(db, 'chatMetadata', chatId, 'userEvents');
            id = history.length ? createSnapshot(uid, chatId, history) : null;
            return addDoc(eventRef, { ...event, uid, snapshotId: id, createdAt: new Date() });
        } catch (e) {
            captureException(e, { extra: { chatId, name: event.name, id } });
        }
    },
};

type MetadataEvent =
    | RegenEvent
    | EditPromptEvent
    | CopyResponse
    | CopyCodeBlock
    | StopGeneration
    | HighlightText
    | CtrlCopy
    | AdditionalFeedback;

type RegenEvent = {
    name: 'regenerate';
    previous: string;
    next: string;
    prompt: string;
    messageIndex: number;
};

type EditPromptEvent = {
    name: 'edit_prompt';
    previousPrompt: string;
    nextPrompt: string;
    previousResponse: string;
    nextResponse: string;
    messageIndex: number;
};

type CopyCodeBlock = {
    name: 'copy_code_block';
    content: string;
    messageIndex: number;
};

type StopGeneration = {
    name: 'stop_generation';
    lastResponse: string;
    messageIndex: number;
};

type AdditionalFeedback = {
    name: 'additional_feedback';
    response: string;
    feedback: any;
    unsafe: any;
    inaccurate: any;
    unhelpful: any;
    messageIndex: number;
};

// pending

type CopyResponse = {
    name: 'copy_response';
};

type HighlightText = {
    name: 'highlight_text';
    content: string;
    offset: number;
};

type CtrlCopy = {
    name: 'control_copied';
    content: string;
    offset: number;
};
