export const colors = {
    brand: {
        900: '#1a365d',
        800: '#153e75',
        700: '#2a69ac',
    },
    darkPurple: {
        '100': '#331e7b',
        '200': '#2c1a6b',
        '300': '#26165c',
        '400': '#231454',
        '500': '#20134D',
        '600': '#1c1145',
        '700': '#190f3d',
        '800': '#130b2e',
        '900': '#0c071e',
    },
    textColor: 'black',
    borderAlt: 'rgb(64,99,171)',
    bgBlue50: 'rgba(14, 22, 34, 0.5)',
    bgBlue70: 'rgba(14, 22, 34, 0.7)',
    bgBlue: 'rgb(14 22 34)',
    chatBg: 'rgb(25,35,49, 0.7)',
    chatBorder: 'rgba(255, 255, 255, 0.2)',
    modalBg: '#0e1622',
    red100: '#CE4545',
    white15: 'rgba(255, 255, 255, 0.15)',
    white60: 'rgba(255, 255, 255, 0.6)',
    white70: 'rgba(255, 255, 255, 0.7)',
    dark60: 'rgba(15, 22, 34, 0.6)',
    darkBlueBg: '#10161E',
    gradientRight: 'linear-gradient(308.32deg, #9747FF 13.08%, #1450FF 91.22%)',
    gradientLeft: 'linear-gradient(296.33deg, #1450FF 11.54%, #9747FF 74.47%)',
    errorGradient: 'linear-gradient(0deg, rgba(206, 69, 69, 0.2), rgba(206, 69, 69, 0.2)), rgba(15, 22, 34, 0.85)',
};
