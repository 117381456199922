import { PersonaEntry } from '@/api/reka';
import { resetApp } from '@/store/root-actions';
import { compact } from '@/utils/helper';
import { Chat, Message } from '@/utils/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ILoggedOutChatState {
    chat: Chat | null;
    showModalType: 'feature' | 'saves' | 'models' | null;
}

export const initialState: ILoggedOutChatState = {
    chat: null,
    showModalType: null,
};

export const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        updateChatResponse: (state, action: PayloadAction<{ messageIndex: number; message: Partial<Message> }>) => {
            const { messageIndex, message } = action.payload;
            if (state.chat) {
                state.chat.history ??= [];
                state.chat.history[messageIndex] = compact({ ...state.chat.history[messageIndex], ...message });
            }
        },
        addChatMessage: (state, action: PayloadAction<Message>) => {
            if (!state.chat) return;
            state.chat.history ??= [];
            state.chat.history.push(action.payload);
        },
        clearChat: (state) => {
            state.chat = null;
        },
        setChat: (state, action: PayloadAction<Chat>) => {
            state.chat = action.payload;
        },
        updateChat: (state, action: PayloadAction<Partial<Chat>>) => {
            if (!state.chat) return;
            state.chat = { ...state.chat, ...action.payload };
        },
        setShowModal: (state, action: PayloadAction<'feature' | 'saves' | 'models' | null>) => {
            state.showModalType = action.payload;
        },
    },
});

export const { updateChatResponse, clearChat, setChat, updateChat, addChatMessage, setShowModal } =
    appConfigSlice.actions;

export default appConfigSlice.reducer;
