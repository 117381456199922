import { PersonaEntry } from '@/api/reka';
import { resetApp } from '@/store/root-actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IAppConfigState {
    enabledFeatures: {
        codeInterpreter?: boolean;
        longContext?: boolean;
        customUrl?: boolean;
        flashDefaultSearch?: boolean;
        modelSelector?: boolean;
        personaSelector?: boolean;
        showSystemPrompts?: boolean;
        exportChat?: boolean;
        interleaveMedia?: boolean;
        audioUpload?: boolean;
        videoUpload?: boolean;
        searchEngine?: boolean;
        retrieval?: boolean;
        rekaCore?: boolean;
    };
    chatParameters: {
        modelName?: string;
        requestOutputLen?: number;
        temperature?: number;
        randomSeed?: number;
        runtimeTopK?: number;
        runtimeTopP?: number;
        repetitionPenalty?: number;
        lenPenalty?: number;
        stopTokens?: string[];
        assistantStartText?: string;
    };
    persona: {
        customPersonas: PersonaEntry[];
    };
}

export const initialState: IAppConfigState = {
    enabledFeatures: {},
    chatParameters: {},
    persona: {
        customPersonas: [],
    },
};

export const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        resetEnabledFeatures: (state) => {
            state.enabledFeatures = {};
        },
        resetChatParams: (state) => {
            state.chatParameters = {};
        },
        toggleFeature: (
            state,
            action: PayloadAction<{
                feature: keyof IAppConfigState['enabledFeatures'];
                enabled: boolean;
            }>,
        ) => {
            state.enabledFeatures[action.payload.feature] = action.payload.enabled;
        },
        setChatParams: (state, action: PayloadAction<IAppConfigState['chatParameters']>) => {
            Object.entries(action.payload).forEach(([name, value]) => {
                if (value === undefined) {
                    // @ts-ignore
                    delete state.chatParameters[name];
                } else {
                    // @ts-ignore
                    state.chatParameters[name] = value;
                }
            });
        },
        setCustomPersonas: (state, action: PayloadAction<PersonaEntry[]>) => {
            state.persona = {
                ...state.persona,
                customPersonas: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, (state) => {
            state.enabledFeatures = {};
            state.chatParameters = {};
            state.persona = { customPersonas: [] };
        });
    },
});

export const { toggleFeature, setChatParams, resetEnabledFeatures, resetChatParams, setCustomPersonas } =
    appConfigSlice.actions;

export default appConfigSlice.reducer;
