import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
    // The styles all button have in common
    baseStyle: {
        borderRadius: 'base', // <-- border radius is same for all variants and sizes
    },
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: 'sm',
            px: 4, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
            fontSize: 'md',
            px: 6, // <-- these values are tokens from the design system
            py: 4, // <-- these values are tokens from the design system
        },
    },
    // Two variants: outline and solid
    variants: {
        link: {
            color: 'text-secondary',
        },
        ghost: {
            color: 'text-secondary',
            _hover: {
                background: 'hover-main',
            },
            _active: {
                background: 'border-main',
            },
        },
        outline: {
            color: 'border-alt',
            _hover: {
                background: 'background-secondary',
            },
        },
        inputElement: {
            textStyle: 'link',
            textDecoration: 'none',
        },
        primary: {
            background: 'gradientRight',
            height: '56px',
            borderRadius: '100px',
            padding: '16px 64px',
            textStyle: 'link',
            textDecoration: 'none',
            width: '100%',
            style: {
                backgroundImage: 'linear-gradient(308.32deg, #9747FF 13.08%, #1450FF 91.22%)',
                backgroundPosition: 'left',
            },
        },
        darkPurple: {
            borderRadius: '6px',
            border: 'none',
            color: 'white',
            background: 'darkPurple.200',
            width: '100%',
            _hover: {
                background: 'darkPurple.400',
            },
            _active: {
                background: 'darkPurple.500',
            },
        },
        purpleOutline: {
            borderRadius: '6px',
            border: '1px solid #8151BC',
            color: 'text-secondary',
            background: 'none',
            width: '100%',
            _hover: {
                background: 'rgba(129,81,188,0.23)',
            },
            _active: {
                background: 'brand-color-bg',
            },
        },
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        variant: 'outline',
    },
});
