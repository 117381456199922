import { resetApp } from '@/store/root-actions';
import { RootState } from '@/store/store';
import { compact } from '@/utils/helper';
import { Chat, Message } from '@/utils/types';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export type IChatsState = ReturnType<typeof chatsAdapter.getInitialState>;

export const chatsAdapter = createEntityAdapter<Chat>({
    selectId: (chat) => chat.id,
    sortComparer: (a, b) => {
        try {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            // @ts-ignore
            return dateB - dateA;
        } catch {
            return 0;
        }
    },
});

export const chatSelectors = chatsAdapter.getSelectors<RootState>((state) => state.chats);

export const chatsSlice = createSlice({
    name: 'chats',
    initialState: chatsAdapter.getInitialState(),
    reducers: {
        fetchChats: chatsAdapter.setMany,
        createChat: chatsAdapter.setOne,
        updateChat: chatsAdapter.updateOne,
        deleteChat: chatsAdapter.removeOne,
        deleteAll: chatsAdapter.removeAll,

        updateChatResponse: (
            chats,
            action: PayloadAction<{ chatId: string; messageIndex: number; message: Partial<Message> }>,
        ) => {
            const { chatId, messageIndex, message } = action.payload;
            const chat = chats.entities[chatId];
            if (chat) {
                chat.history ??= [];
                chat.history[messageIndex] = compact({ ...chat.history[messageIndex], ...message });
            }
        },

        clearChatContext: (chats, action: PayloadAction<{ chatId: string }>) => {
            const { chatId } = action.payload;
            const chat = chats.entities[chatId];
            const last = chat?.history.at(-1);
            if (last && last.type === 'model') {
                // @ts-ignore
                last.clear_context = true;
            }
        },

        addMessageToChatHistory: (chats, action: PayloadAction<{ chatId: string; message: Message }>) => {
            const { chatId, message } = action.payload;
            const chat = chats.entities[chatId];
            if (chat) {
                chat.history ??= [];
                chat.history.push(compact(message));
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, (state) => {
            state.entities = {};
            state.ids = [];
        });
    },
});
