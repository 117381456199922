import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { clearLoading } from '@/store/persist';
import appConfigSlice from '@/store/slices/app-config-slice';
import { chatsSlice } from '@/store/slices/chats-slice';
import { uploadedFilesSlice } from '@/store/slices/uploaded-files-slice';
import { combineReducers } from '@reduxjs/toolkit';

import authSlice from './slices/auth-slice';
import chatHistorySlice from './slices/chat-history-slice';
import errorMessageSlice from './slices/error-message-slice';
import loggedOutChatSlice from './slices/logged-out-chat-slice';
import requestOptionSlice from './slices/request-option-slice';

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    whitelist: ['appConfig'],
    transforms: [clearLoading],
};

export const rootReducer = combineReducers({
    auth: authSlice,
    chatHistory: chatHistorySlice,
    loggedOutChatSlice: loggedOutChatSlice,
    errorMessage: errorMessageSlice,
    requestOption: requestOptionSlice,
    appConfig: appConfigSlice,
    chats: chatsSlice.reducer,
    uploadedFiles: uploadedFilesSlice.reducer,
});

export default persistReducer(persistConfig, rootReducer);
