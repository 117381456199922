import { compact } from '@/utils/helper';
import { Message } from '@/utils/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ChatHistory = {
    id: string;
    title: string;
    convos: Message[];
    retrievalDataset?: string;
    persona?: string;
    modelName?: string;
    loading?: boolean;
};
export interface IChatHistoryState {
    chatHistory: ChatHistory[];
    systemConvos: Message[] | null;
}

export const initialState: IChatHistoryState = {
    chatHistory: [],
    systemConvos: null,
};

export const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState,
    reducers: {
        setChatHistory: (state, action: PayloadAction<ChatHistory[]>) => {
            state.chatHistory = action.payload;
        },

        createChatResponse: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(id));
            if (chatItem) {
                chatItem.convos ??= [];
                chatItem.convos.push({
                    type: 'model',
                    text: '',
                });
                chatItem.loading = true;
            }
        },

        updateChatResponse: (
            state,
            action: PayloadAction<{ chatId: number; responseIndex: number; response: Message }>,
        ) => {
            const { chatId, responseIndex, response } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(chatId));
            if (chatItem && chatItem.convos) {
                chatItem.convos[responseIndex] = { ...chatItem.convos[responseIndex], ...response };
            }
        },

        updateChatLoading: (state, action: PayloadAction<{ chatId: number; loading: boolean }>) => {
            const { chatId, loading } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(chatId));
            if (chatItem) {
                chatItem.loading = loading;
            }
        },

        updateChatTitle: (state, action: PayloadAction<{ id: number | string | null; title: string }>) => {
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(action.payload.id));
            if (chatItem) chatItem.title = action.payload.title;
        },

        updateChatHistory: (state, action: PayloadAction<{ id: number | string | null; response: Message }>) => {
            const { id, response } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(id));
            if (chatItem) {
                chatItem.convos ??= [];
                chatItem.convos.push(response);
            }
        },

        updateChatSettings: (state, action: PayloadAction<Partial<ChatHistory> & { id: number | string | null }>) => {
            const { id, ...rest } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(id));
            if (chatItem) {
                for (let key of Object.keys(compact(rest))) {
                    // @ts-ignore
                    chatItem[key] = rest[key];
                }
            }
        },

        addMessageToChatHistory: (
            state,
            action: PayloadAction<Message & { chatId: string | number | string[] | null }>,
        ) => {
            const { chatId, ...rest } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(chatId));
            if (chatItem) {
                chatItem.convos ??= [];
                chatItem.convos.push(compact(rest));
            }
        },

        addResendMessageToChatHistory: (
            state,
            action: PayloadAction<{ chatId: string | number | string[] | null; updatedCurrentChat?: Message[] }>,
        ) => {
            const { chatId, updatedCurrentChat } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(chatId));
            if (chatItem && updatedCurrentChat) {
                chatItem.convos = updatedCurrentChat;
            }
        },

        createNewChatHistory: (state, action: PayloadAction<ChatHistory>) => {
            const newChat = {
                // id: state.chats.length + 1,
                ...action.payload,
            };
            state.chatHistory = [compact(newChat), ...state.chatHistory];
        },

        updateChatHistoryFeedBack: (
            state,
            action: PayloadAction<{ chatId: string | number | null; messageIndex: number; intent: boolean }>,
        ) => {
            const { chatId, messageIndex, intent } = action.payload;
            const chatItem = state.chatHistory.find((item) => Number(item.id) === Number(chatId));
            if (chatItem && chatItem.convos) {
                const message = chatItem.convos[messageIndex];
                if (message.type === 'model') {
                    message.thumbs_up = intent;
                }
            }
        },

        setSystemConvos: (state, action: PayloadAction<Message[]>) => {
            state.systemConvos = action.payload;
        },

        clearChatHistory: (state) => {
            state.chatHistory = [];
        },
    },
});

export const {
    updateChatTitle,
    updateChatHistory,
    setChatHistory,
    addMessageToChatHistory,
    createNewChatHistory,
    clearChatHistory,
    addResendMessageToChatHistory,
    updateChatHistoryFeedBack,
    createChatResponse,
    updateChatSettings,
    updateChatResponse,
    updateChatLoading,
    setSystemConvos,
} = chatHistorySlice.actions;

export default chatHistorySlice.reducer;
