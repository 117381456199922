import { resetApp } from '@/store/root-actions';
import { errorMessageType } from '@/utils/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IErrorMessageState {
    errorMessage: errorMessageType;
}

export const initialState: IErrorMessageState = {
    errorMessage: {
        currentId: null,
        message: '',
    },
};

export const errorMessageSlice = createSlice({
    name: 'errorMessage',
    initialState,
    reducers: {
        setErrorMessage: (state, action: PayloadAction<errorMessageType>) => {
            state.errorMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, (state) => {
            state.errorMessage = {
                currentId: null,
                message: '',
            };
        });
    },
});

export const { setErrorMessage } = errorMessageSlice.actions;

export default errorMessageSlice.reducer;
