export const textStyles = {
    // The styles all button have in common

    'body-12': {
        fontSize: ['xs'],
        fontFamily: 'body',
        fontWeight: '400',
        lineHeight: '14px',
        color: 'border-alt',
    },

    h1: {
        fontSize: ['xl', '2xl'],
        fontWeight: '500',
        lineHeight: '43.2px',
        fontFamily: 'heading',
        color: 'text-secondary',
    },

    'body-16': {
        fontSize: ['md'],
        fontWeight: '400',
        lineHeight: '19.2px',
        fontFamily: 'body',
        color: 'border-alt',
    },

    'body-18': {
        fontSize: ['md'],
        fontWeight: '400',
        lineHeight: '22px',
        fontFamily: 'body',
        color: 'border-alt',
    },

    'body-28': {
        fontSize: ['lg', 'xl'],
        fontWeight: '500',
        lineHeight: '34px',
        fontFamily: 'heading',
        color: 'text-secondary',
    },

    link: {
        fontSize: ['lg'],
        fontWeight: '500',
        lineHeight: '21.6px',
        fontFamily: 'link',
        textDecoration: 'underline',
        textTransform: 'uppercase',
        color: 'white',
    },
};
