import { resetApp } from '@/store/root-actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IRequestOptionState {
    modelName: string;
    useSearchEngine: boolean;
    useCodeInterpreter: boolean;
    retrievalDataset?: string;
    persona?: string;
}

export const initialState: IRequestOptionState = {
    modelName: 'default',
    useSearchEngine: false,
    useCodeInterpreter: false,
    retrievalDataset: undefined,
    persona: undefined,
};

export const requestOptionSlice = createSlice({
    name: 'requestOptionSlice',
    initialState,
    reducers: {
        setRequestOption: (state, action: PayloadAction<IRequestOptionState>) => {
            Object.assign(state, action.payload);
        },
        setRetrievalDataset: (state, action: PayloadAction<string>) => {
            state.retrievalDataset = action.payload;
        },
        resetRequestOption: (state) => {
            Object.assign(state, { ...initialState });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, (state) => {
            state.modelName = 'default';
            state.useSearchEngine = false;
            state.useCodeInterpreter = false;
            state.retrievalDataset = undefined;
            state.persona = undefined;
        });
    },
});

export const { setRequestOption, resetRequestOption, setRetrievalDataset } = requestOptionSlice.actions;

export default requestOptionSlice.reducer;
