import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseInput = definePartsStyle({
    field: {
        border: '1px solid',
        bordercolor: 'text-secondary',
        background: 'background-secondary',
        borderRadius: '100px',
        height: '56px',
        textStyle: 'body-18',
        px: '24px',
    },
    addon: {
        border: '1px solid',
        borderColor: 'gray.200',
        background: 'gray.200',
        borderRadius: 'full',
        color: 'gray.500',
    },
    element: {
        textStyle: 'link',
        borderRadius: 'full',
    },
});

export const inputTheme = defineMultiStyleConfig({
    variants: { baseInput },
});
