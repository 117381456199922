import { resetApp } from '@/store/root-actions';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export type UploadedFile = {
    id: string;
    url: string;
    filename: string;
    type: 'video' | 'audio' | 'image' | 'code_file' | 'application' | 'custom';
    createdAt: string;
    lastUsed: string;
};
export type IUploadedFilesState = ReturnType<typeof uploadedFilesAdapter.getInitialState>;

export const uploadedFilesAdapter = createEntityAdapter<UploadedFile>({
    selectId: (file) => file.url,
    sortComparer: (a, b) => {
        try {
            const dateA = new Date(a.lastUsed);
            const dateB = new Date(b.lastUsed);

            // @ts-ignore
            return dateB - dateA;
        } catch {
            return 0;
        }
    },
});

export const uploadedFilesSlice = createSlice({
    name: 'uploadedFiles',
    initialState: uploadedFilesAdapter.getInitialState(),
    reducers: {
        saveFile: uploadedFilesAdapter.addOne,
        fetchFiles: uploadedFilesAdapter.setMany,
        updateFile: uploadedFilesAdapter.updateOne,
        removeFile: uploadedFilesAdapter.removeOne,
        removeAll: uploadedFilesAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, (state) => {
            state.entities = {};
            state.ids = [];
        });
    },
});
