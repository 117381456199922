import { useDispatch, useSelector } from 'react-redux';

import { configValue } from '@/config/config-value';
import { resetChatParams, resetEnabledFeatures, setChatParams, toggleFeature } from '@/store/slices/app-config-slice';

import { setCustomPersonas } from '../slices/app-config-slice';
import { systemPersonas } from '../slices/system-personas';
import { RootState } from '../store';

export const useAppConfig = () => {
    const dispatch = useDispatch();
    const appConfig = useSelector((state: RootState) => state.appConfig);

    function withDispatch<F extends (...args: any[]) => any>(fn: F) {
        return (...payload: Parameters<F>) => dispatch(fn(...payload));
    }

    const allPersonas = [...systemPersonas, ...(appConfig.persona?.customPersonas ?? [])];

    return {
        chatParameters: appConfig.chatParameters,
        enabledFeatures: { ...configValue.defaultFeatureSet, ...appConfig.enabledFeatures },
        resetEnabledFeaturesAction: withDispatch(resetEnabledFeatures),
        resetChatParamsAction: withDispatch(resetChatParams),
        toggleFeatureAction: withDispatch(toggleFeature),
        setChatParamsAction: withDispatch(setChatParams),
        systemPersonas: systemPersonas,
        customPersonas: appConfig.persona?.customPersonas ?? [],
        allPersonas,
        setCustomPersonasAction: withDispatch(setCustomPersonas),
    };
};
