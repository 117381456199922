import { configType } from '@/utils/types';

const APP_ENV = process.env.appEnv || 'development';

function localConfig(): configType {
    return {
        apiKey: process.env.firebaseApiKey,
        authDomain: process.env.firebaseAuthDomain,
        auth0IssuerUrl: process.env.auth0IssuerUrl,
        projectId: process.env.firebaseProjectId,
        storageBucket: process.env.firebaseStorageBucket,
        messagingSenderId: process.env.firebaseMessaginSenderId,
        elevenlabsKey: process.env.elevenlabsKey,
        appId: process.env.firebaseAppId,
        rekaApiKey: process.env.rekaApiKey ?? '',
        baseURL: process.env.backendServer ?? '/api',
        backendBaseURL: 'https://chat.aws-staging.reka.ai/api',
        showAdminSettings: true,
        conversationLengthLimit: Infinity,
        defaultFeatureSet: {
            codeInterpreter: true,
            longContext: true,
            customUrl: true,
            flashDefaultSearch: true,
            rekaCore: true,
            modelSelector: true,
            personaSelector: true,
            showSystemPrompts: false,
            exportChat: true,
            interleaveMedia: true,
            audioUpload: true,
            videoUpload: true,
            searchEngine: true,
            retrieval: true,
        },
    };
}

function stagingConfig(): configType {
    return {
        apiKey: process.env.firebaseApiKey,
        authDomain: process.env.firebaseAuthDomain,
        auth0IssuerUrl: process.env.auth0IssuerUrl,
        projectId: process.env.firebaseProjectId,
        storageBucket: process.env.firebaseStorageBucket,
        messagingSenderId: process.env.firebaseMessaginSenderId,
        elevenlabsKey: process.env.elevenlabsKey,
        appId: process.env.firebaseAppId,
        rekaApiKey: process.env.rekaApiKey ?? '',
        baseURL: process.env.backendServer ?? '/api',
        backendBaseURL: 'https://chat.aws-staging.reka.ai/api',
        showAdminSettings: true,
        conversationLengthLimit: Infinity,
        defaultFeatureSet: {
            codeInterpreter: true,
            longContext: true,
            customUrl: true,
            flashDefaultSearch: true,
            rekaCore: true,
            modelSelector: true,
            personaSelector: true,
            showSystemPrompts: false,
            exportChat: true,
            interleaveMedia: false,
            audioUpload: true,
            videoUpload: true,
            searchEngine: true,
            retrieval: true,
        },
    };
}

function prodConfig(): configType {
    return {
        apiKey: process.env.firebaseApiKey,
        authDomain: process.env.firebaseAuthDomain,
        auth0IssuerUrl: process.env.auth0IssuerUrl,
        projectId: process.env.firebaseProjectId,
        storageBucket: process.env.firebaseStorageBucket,
        messagingSenderId: process.env.firebaseMessaginSenderId,
        elevenlabsKey: process.env.elevenlabsKey,
        appId: process.env.firebaseAppId,
        rekaApiKey: process.env.rekaApiKey ?? '',
        baseURL: process.env.backendServer ?? '/api',
        backendBaseURL: 'https://chat.reka.ai/api',
        showAdminSettings: false,
        conversationLengthLimit: 24,
        defaultFeatureSet: {
            codeInterpreter: false,
            longContext: true,
            customUrl: false,
            flashDefaultSearch: true,
            rekaCore: true,
            modelSelector: false,
            personaSelector: false,
            showSystemPrompts: false,
            exportChat: false,
            interleaveMedia: false,
            audioUpload: false,
            videoUpload: true,
            searchEngine: false,
            retrieval: false,
        },
    };
}

export const configValue =
    APP_ENV === 'development' ? localConfig() : APP_ENV === 'production' ? prodConfig() : stagingConfig();
