export const fonts = {
    body: "'Figtree', Helvetica, sans-serif",
    heading: "'Figtree', Helvetica, sans-serif",
    link: 'input-mono-medium',
};

export const fontSizes = {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '28px',
    '2xl': '36px',
};
