import { resetApp } from '@/store/root-actions';
import { User } from '@firebase/auth';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IAuthState {
    apiKey: string;
    showTerms: boolean;
    isLoading: boolean;
    noFirebase: boolean;
    appUser: User | null;
}

export const initialState: IAuthState = {
    apiKey: '',
    showTerms: false,
    isLoading: true,
    noFirebase: false,
    appUser: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuth: (state, action: PayloadAction<Partial<IAuthState>>) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                if (value !== undefined) {
                    // @ts-ignore
                    state[key] = value;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, (state) => {
            state.appUser = null;
            state.isLoading = false;
            state.apiKey = '';
        });
    },
});

export const { updateAuth } = authSlice.actions;

export default authSlice.reducer;
