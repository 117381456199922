// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import { configValue } from './config-value';

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: configValue.apiKey,
    authDomain: configValue.authDomain,
    projectId: configValue.projectId,
    storageBucket: configValue.storageBucket,
    messagingSenderId: configValue.messagingSenderId,
    appId: configValue.appId,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore(firebaseApp);

export { firebaseApp, auth, db };
