import { switchTheme } from '@/theme/components/switch';
import { tabsTheme } from '@/theme/components/tab';
import { createMultiStyleConfigHelpers, cssVar, extendTheme } from '@chakra-ui/react';

import { colors } from './color-theme';
import { Button } from './components/button';
import { inputTheme } from './components/input';
import { textStyles } from './components/text-styles';
import { fontSizes, fonts } from './fonts';

// This is used to cache color mode in local storage. To reset color mode preference change the hash in this key
export const COLOR_MODE_KEY = 'reka-color-mode-F1EA842B0A09';

export const theme = extendTheme({
    config: {
        cssVarPrefix: 'reka',
        initialColorMode: 'light',
        useSystemColorMode: true,
    },
    semanticTokens: {
        colors: {
            'chakra-body-text': {
                _light: 'rgba(12, 22, 38, 1)',
                _dark: 'whiteAlpha.800',
            },
            'chakra-body-bg': {
                _light: '#EAEEF6',
                _dark: 'darkBlueBg',
            },
            'chakra-border-color': { _dark: 'whiteAlpha.400' },
            'chakra-inverse-text': { _dark: 'gray.800' },
            'chakra-subtle-bg': { _dark: 'gray.700' },
            'chakra-subtle-text': { _dark: 'whiteAlpha.700' },
            'chakra-placeholder-color': { _dark: 'gray.500' },
            'text-primary': {
                default: 'rgba(12, 22, 38, 1)',
                _dark: 'whiteAlpha.800',
            },
            'text-secondary': {
                default: 'rgba(12, 22, 38, 0.8)',
                _dark: 'whiteAlpha.800',
            },
            'text-subtle': {
                default: 'rgba(12, 22, 38, 0.6)',
                _dark: 'whiteAlpha.600',
            },
            'header-underline': {
                default: 'rgba(189, 193, 198, 0.8)',
                _dark: 'rgba(255, 255, 255, 0.2)',
            },
            'header-primary': {
                default: 'rgba(12, 22, 38, 1)',
                _dark: 'white',
            },
            'header-secondary': {
                default: 'rgba(12, 22, 38, 0.8)',
                _dark: 'rgba(255, 255, 255, 0.7)',
            },
            'background-main': {
                default: '#EAEEF6',
                _dark: 'rgb(14 22 34)',
            },
            'background-secondary': {
                default: '#E1E5EB',
                _dark: 'rgb(25,35,49, 0.7)',
            },
            'border-main': {
                default: 'rgba(12, 22, 38, 0.6)',
                _dark: 'rgba(255, 255, 255, 0.2)',
            },
            'border-alt': {
                default: 'rgba(12, 22, 38, 1)',
                _dark: 'rgba(255, 255, 255, 0.6)',
            },
            'outline-alt': {
                default: 'rgba(12, 22, 38, 0.6)',
                _dark: 'rgb(64,99,171)',
            },
            'hover-main': {
                default: 'rgba(12, 22, 38, 0.2)',
                _dark: 'rgba(255, 255, 255, 0.2)',
            },
            'modal-background': {
                default: '#FFFFFF',
                _dark: '#0F1622',
            },
            'setting-section': {
                default: '#EAEEF6',
                _dark: '#22262D',
            },
            'brand-color-bg': {
                default: 'rgba(129,81,188,0.7)',
                _dark: 'darkPurple.500',
            },
        },
    },
    colors,
    fonts,
    fontSizes,
    textStyles,
    components: {
        Button,
        Input: inputTheme,
        Tabs: tabsTheme,
        Menu: createMultiStyleConfigHelpers(['menu', 'item', 'list']).defineMultiStyleConfig({
            baseStyle: {
                item: {
                    _dark: {
                        [cssVar('menu-bg').variable]: '#16202c',
                    },
                },
                list: {
                    _dark: {
                        [cssVar('menu-bg').variable]: '#16202c',
                    },
                },
            },
        }),
        Switch: switchTheme,
    },
});
