// withPageAuthRequired
import { browserSessionPersistence, signInWithCustomToken } from 'firebase/auth';
import fetch from 'isomorphic-unfetch';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { UserResource } from '@/api/firestore';
import { auth } from '@/config/firebase';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useUser } from '@auth0/nextjs-auth0/client';
import { captureException, setUser } from '@sentry/nextjs';

const UserAuthenticationContext = React.createContext<{ isReady: boolean }>({ isReady: false } as any);

export function UserAuthenticationProvider({
    children,
    isPublicPath,
}: {
    children: React.ReactNode;
    isPublicPath: boolean;
}) {
    const router = useRouter();
    const isAuthPath = router.pathname.startsWith('/auth');
    const { authenticated, user, isLoading } = useAuthentication();

    useEffect(() => {
        // Redirect users to correct auth page
        if (isLoading || isPublicPath) {
            return;
        }

        if (!authenticated) {
            if (!isAuthPath) {
                router.push('/auth/login');
            }
            return;
        }

        if (user && !user.email_verified) {
            router.push('/auth/verify-email');
            return;
        }

        if (isAuthPath) {
            router.push('/chat');
            return;
        }
    }, [isLoading, authenticated, isAuthPath]);

    return (
        <UserAuthenticationContext.Provider value={{ isReady: (!isLoading && authenticated) || isAuthPath }}>
            {children}
        </UserAuthenticationContext.Provider>
    );
}

export function useOnReady(callback: () => void, dependencies: any[]) {
    const { isReady } = useUserAuth();
    useEffect(() => {
        if (isReady) {
            callback();
        }
    }, [isReady, ...dependencies]);
}

function useAuthentication() {
    const { user, error, isLoading } = useUser();
    const [authenticated, setAuthenticated] = useState(false);
    const { setAppUserAction, setApiKeyAction, updateAuthAction } = useAuthStore();
    const [completed, setCompleted] = useState(false);
    const { toggleFeatureAction } = useAppConfig();
    useEffect(() => {
        const syncFirebase = async () => {
            if (!user) {
                auth.signOut();
                setAuthenticated(false);
                setAppUserAction(null);
            } else {
                if (auth.currentUser && auth.currentUser.uid === user.sub) {
                    setAuthenticated(true);
                    setAppUserAction(auth.currentUser);
                    setUser({ email: user?.email || '', id: user?.sub || '' });
                } else {
                    const token = await getToken();
                    const result = await signInWithCustomToken(auth, token);
                    if (result) {
                        auth.setPersistence(browserSessionPersistence);
                    }
                    setAuthenticated(true);
                    setAppUserAction(result.user);
                }
                const { data, err } = await UserResource.fetch(auth.currentUser!.uid);
                if (data) {
                    setApiKeyAction(data.apiKey);
                    updateAuthAction({ showTerms: !data.userAcceptedTerms });
                    if (data.featureSet) {
                        Object.entries(data.featureSet).forEach(([feature, enabled]) => {
                            // @ts-ignore
                            toggleFeatureAction({ feature, enabled });
                        });
                    }
                } else {
                    captureException(err);
                }
            }
            setCompleted(true);
        };
        if (isLoading) return;
        Promise.race([
            syncFirebase(),
            new Promise((resolve, reject) => {
                setTimeout(resolve, 8000, 'timeout');
            }),
        ]).then((ret) => {
            if (ret === 'timeout') {
                console.log(auth);
                setCompleted(true);
                updateAuthAction({
                    showTerms: false,
                    noFirebase: true,
                });
                setAuthenticated(true);
                setUser({ email: user?.email || '', id: user?.sub || '' });
            }
        });
    }, [isLoading, user]);
    return { authenticated, user, isLoading: !completed };
}

export function useUserAuth() {
    return React.useContext(UserAuthenticationContext);
}

function getToken() {
    return fetch('/bff/auth/firebase_token')
        .then((res) => res.json())
        .then((res) => res.token);
}
