import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
    tab: {
        fontWeight: 'normal',
        _selected: {
            color: '#8151BC',
        },
    },
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle });
