import { Chat, Message } from './types';

export const handleShowChat = (data: Chat[]) => {
    return data?.length > 0;
};

export const getChatHistory = (chats: Chat[], chatId: string | number) => {
    return chats?.find((data) => data.id === chatId) as Chat;
};

export function showBorderLine(chats: Chat[]) {
    return chats.length >= 9;
}

export function removeEmojis(text: string) {
    const emojiRegex =
        /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}]/gu;
    return text.replaceAll(emojiRegex, '');
}

export function compact<T extends Record<string, any>>(hash: T): T {
    return Object.entries(hash).reduce((prev, [key, val]) => {
        if (val !== null && val !== undefined) {
            // @ts-ignore
            prev[key] = val;
        }
        return prev;
    }, {} as T);
}

export function sliceContext(history: Message[], messageIndex?: number) {
    let start = -1;
    history.forEach(({ clear_context }, index) => {
        if (!!clear_context) start = index;
    });
    if (start === -1) return history;
    if (messageIndex === undefined) {
        return history.slice(start + 1);
    }
    return history.slice(start + 1, messageIndex);
}

export function lastContextLength(history: Message[] = []) {
    if (!history) return 0;
    return sliceContext(history).length;
}
