import { persistStore } from 'redux-persist';

import { IAppConfigState } from '@/store/slices/app-config-slice';
import { IAuthState } from '@/store/slices/auth-slice';
import { IChatHistoryState } from '@/store/slices/chat-history-slice';
import { IChatsState } from '@/store/slices/chats-slice';
import { IErrorMessageState } from '@/store/slices/error-message-slice';
import { ILoggedOutChatState } from '@/store/slices/logged-out-chat-slice';
import { IRequestOptionState } from '@/store/slices/request-option-slice';
import { IUploadedFilesState } from '@/store/slices/uploaded-files-slice';
import { configureStore } from '@reduxjs/toolkit';

import { logger } from './middleware/logger';
import rootReducer from './root-reducer';

type IStore = {
    requestOption: IRequestOptionState;
    chatHistory: IChatHistoryState; // depreciated
    loggedOutChatSlice: ILoggedOutChatState; // depreciated
    chats: IChatsState;
    errorMessage: IErrorMessageState;
    auth: IAuthState;
    appConfig: IAppConfigState;
    uploadedFiles: IUploadedFilesState;
};

const customMiddlewares = process.env.appEnv === 'production' || process.env.appEnv === 'staging' ? [] : [logger];
export const store = configureStore<IStore>({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(customMiddlewares) as any,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
